import React from "react"
import BgImg from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const TextureMarbleStyled = styled(BgImg)`
  width: 100%;
  height: 100%;
  position: static !important;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
`

const getData = graphql`
  {
    textureMarble: file(relativePath: { eq: "texture-marble.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const TextureMarble = props => {
  const data = useStaticQuery(getData)
  const imageData = data.textureMarble.childImageSharp.fluid
  return <TextureMarbleStyled Tag="div" fluid={imageData} />
}

export default TextureMarble
