import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { standardWrapper, colors, headlineOne, buttonOne } from "../Utilities"

import LogoDarkBlue from "../elements/LogoDarkBlue"

const CalloutSectionStyled = styled.section`
  position: relative;
  margin-top: 15rem;
  padding-top: 5rem;
  background: ${colors.colorSecondary};
  text-align: center;

  .icon-logo {
    position: absolute;
    top: -7.5rem;
    right: 0;
    left: 0;
    width: 15rem;
    margin: 0 auto;
  }

  .wrapper {
    ${standardWrapper};
  }

  .header-section {
    width: 100%;

    h2 {
      ${headlineOne};
      color: ${colors.colorPrimary};

      span {
        @media (min-width: 768px) {
          display: block;
        }
      }

      span:last-of-type {
        font-style: italic;
      }
    }
  }

  .button-section {
    width: 100%;

    a {
      ${buttonOne};
      color: ${colors.colorPrimary};
    }
  }
`

const CalloutSection = ({ titleTop, titleBot, btnText, btnLink }) => {
  return (
    <CalloutSectionStyled>
      <div className="wrapper">
        <div className="header-section">
          <h2>
            <span>{titleTop}</span>
            <span>{titleBot}</span>
          </h2>
        </div>
        <div className="button-section">
          <Link to={`/${btnLink}`}>{btnText}</Link>
        </div>
      </div>
      <div className="icon-logo">
        <LogoDarkBlue />
      </div>
    </CalloutSectionStyled>
  )
}

export default CalloutSection
