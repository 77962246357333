import React from "react"
import styled from "styled-components"
import { headlineOne, colors, standardWrapper, bodyCopy } from "../Utilities"

const IntroSection = styled.section`
  text-align: center;
  overflow: hidden;

  .wrapper {
    ${standardWrapper};
  }

  h2 {
    ${headlineOne}
    color: ${colors.colorPrimary};

    span:last-of-type {
      font-style: italic;

      @media(min-width: 768px) {
        display: block;
      }
    }
  }

  p {
    ${bodyCopy};
    text-align: center;
    
    @media(min-width: 768px) {
      text-align: left;
    }
  }
`

const Intro = ({ title, italic, content }) => {
  return (
    <IntroSection>
      <div className="wrapper">
        <div>
          <h2>
            <span dangerouslySetInnerHTML={{ __html: title }} />{" "}
            <span dangerouslySetInnerHTML={{ __html: italic }} />
          </h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </IntroSection>
  )
}

export default Intro
