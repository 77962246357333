import React from "react"
import BgImg from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const TextureCopperStyled = styled(BgImg)`
  width: 100%;
  height: 100%;
  position: static !important;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
`

const getData = graphql`
  {
    textureCopper: file(relativePath: { eq: "texture-copper.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const TextureCopper = props => {
  const data = useStaticQuery(getData)
  const imageData = data.textureCopper.childImageSharp.fluid
  return <TextureCopperStyled Tag="div" fluid={imageData} />
}

export default TextureCopper
