import React from "react"
import BackgroundImg from "gatsby-background-image"
import styled from "styled-components"

const HeroImageComponent = styled.div`
  position: relative;
  height: 40rem;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 50rem;
  }

  @media (min-width: 1025px) {
    height: 75vh;
  }

  .hero-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .hero-image {
    width: 100%;
    height: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

const HeroImage = ({ fluid }) => {
  return (
    <HeroImageComponent>
      <div className="hero-container">
        <BackgroundImg className="hero-image" Tag="div" fluid={fluid} />
      </div>
    </HeroImageComponent>
  )
}

export default HeroImage
